import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="dropdown"
export default class extends Controller {
  
  static targets = ["menu"]

  connect() {
    console.log("Dropdown controller connected.", this.element)
    useClickOutside(this)
  }

  clickOutside(event) {
    this.hide()
  }

  toggle() {
    console.log('toggle dropdown menu')
    this.menuTarget.classList.toggle('hidden');
  }

  hide() {
    console.log('hide dropdown menu')
    this.menuTarget.classList.add('hidden');
  }


}
